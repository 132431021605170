
import Vue from "vue";
import ListTile from "@/components/molecules/lists/ListTile.vue";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";
import { MaterialItem, MaterialItemEnum } from "@/lib/models";

interface Data {}

interface Methods {
  onCheck: () => void;
}

interface Computed {
  title?: string;
  singlelineLabel: string;
  // "singleline-text" | "multiline-text" | "image-square" | "image-portrait" | "image-landscape"
  inputType: MaterialItemEnum;
  imageLabel: string;
  isRequired: boolean;
  isCheck: boolean;
}

interface Props {
  isBasic: boolean;
  item: MaterialItem;
  checkedItems: MaterialItem[];
  titleLimit: number;
  updateItem: (item: MaterialItem) => void;
  updateCheckedItems: (item: MaterialItem) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ListTile, CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon },

  props: {
    isBasic: {
      type: Boolean,
      default: false,
    },
    item: Object as Vue.PropType<MaterialItem>,
    checkedItems: Array as Vue.PropType<MaterialItem[]>,
    titleLimit: {
      type: Number,
      default: 30,
    },
    updateItem: Function,
    updateCheckedItems: Function,
  },

  computed: {
    title: {
      get() {
        const { item } = this;
        return item.title;
      },
      set(title) {
        const { item, updateItem } = this;
        const updatedItem = {
          ...item,
          title,
        };

        updateItem(updatedItem);
      },
    },
    inputType() {
      const { item } = this;
      return item.type;
    },
    singlelineLabel() {
      const { item, inputType } = this;

      switch (inputType) {
        case MaterialItemEnum.TEXT_SINGLELINE:
          return `${item.title}`;

        default:
          return "";
      }
    },
    imageLabel() {
      const { inputType } = this;
      let type = "";
      switch (inputType) {
        case MaterialItemEnum.IMAGE_SQUARE:
          type = "正方形画像の";
          break;
        case MaterialItemEnum.IMAGE_PORTRATE:
          type = "長方形（縦）画像の";
          break;
        case MaterialItemEnum.IMAGE_LANDSCAPE:
          type = "長方形（横）画像の";
          break;
        default:
          break;
      }

      return `${type}パーツ（30文字以内）`;
    },
    isCheck() {
      const { item, checkedItems } = this;
      return checkedItems.some((checkedItem) => checkedItem.id === item.id);
    },
    isRequired() {
      return false;
    },
  },

  methods: {
    onCheck() {
      const { item, updateCheckedItems } = this;
      if (updateCheckedItems) {
        updateCheckedItems(item);
      }
    },
  },
});
